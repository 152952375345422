import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { RxCross2 } from "react-icons/rx";

const ConfigDetailPopup = (monitoringConfig) => {
    const metricFilter = monitoringConfig?.metricFilterGroups?.[0]?.filters;
    const dimensionFilter = monitoringConfig?.dimensionFilterGroups?.[0]?.filters;
    const entitiId = monitoringConfig?.entityIds;

    confirmAlert({
        customUI: ({ onClose }) => {
            const renderContent = () => {
                return (
                    <div
                        className="bg-white"
                        style={{
                            background: "#eae4e4",
                            padding: "18px",
                            borderRadius: "20px",
                            width: '50vw',
                            maxHeight: '80vh',
                            position: 'relative',
                            overflowY: 'auto',
                        }}
                    >
                        <h2 sx={{ marginBottom: '20px' }}>{monitoringConfig?.title}</h2>
                        {dimensionFilter?.length && (
                            <div sx={{ marginBottom: '20px' }}>
                                <h3>Dimension Filter</h3>
                                <div style={{ columnCount: 3 }}>
                                    {dimensionFilter?.map((val, indx) => (
                                        <ul key={indx}>
                                            <li>DimensionName: {val?.dimensionName}</li>
                                            <li>Expressions: {val?.expressions?.[0]}</li>
                                            <li>Operator: {val?.operator}</li>
                                        </ul>
                                    ))}
                                </div>
                            </div>
                        )}
                        {metricFilter?.length && (
                            <div sx={{ marginBottom: '20px' }}>
                                <h3>Metric filter</h3>
                                <div style={{ columnCount: 3 }}>
                                    {metricFilter?.map((val, indx) => (
                                        <ul key={indx}>
                                            <li>ComparisonValue: {val?.comparisonValue}</li>
                                            <li>MetricName: {val?.metricName}</li>
                                            <li>Operator: {val?.operator}</li>
                                        </ul>
                                    ))}
                                </div>
                            </div>
                        )}
                        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                            <p>Enitity Type  {monitoringConfig?.entityType}</p>
                        </div>
                        {entitiId?.length && (
                            <div sx={{ marginBottom: '20px' }}>
                                <h3>Entities</h3>
                                <div style={{ columnCount: 3 }}>
                                    {entitiId?.map((val, indx) => (
                                        <ul key={indx}>
                                            <li>{val}</li>
                                        </ul>
                                    ))}
                                </div>
                            </div>
                        )}

                        <div style={{ position: 'absolute', top: '18px', right: '18px' }}>
                            <RxCross2
                                onClick={onClose}
                                className={'pointer-cell-icon'}
                                size={20}
                                color='#4d4b4b'
                                backgroundColor='none'
                            />
                        </div>
                    </div>
                )
            };

            return renderContent();
        },
    });

};

export default ConfigDetailPopup;
