import React, { useState, useEffect } from 'react';
import { Box, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress } from '@mui/material';
import useLedgerBalanceDetail from './useLedgerBalance';
import download from '../../assets/icons/Download_Icon.png'
import moment from 'moment';
import generatePDF from '../utils/generatePDF';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { formatCurrency } from '../utils/currencyFormatter';
import cancel from '../../assets/icons/delete (7) 1.png'
import cancelHover from '../../assets/icons/delete (6) 1.png'

const LedgerBalance = ({ openLedgerBalanceModal, handleClose, selectedMonth, setSelectedMonth }) => {
  const advertiserId = openLedgerBalanceModal?.data?.advertiserId;
  const [formValues, setFormValues] = useState({});
  const [balanceValues, setBalanceValues] = useState({});
  const { data, table3, loading, getLedgerDetails } = useLedgerBalanceDetail(advertiserId, setSelectedMonth);
  const [isDatePickerDisabled, setIsDatePickerDisabled] = useState(false);

  useEffect(() => {
    setIsDatePickerDisabled(loading);
  }, [loading]);

  useEffect(() => {
    if (openLedgerBalanceModal?.data) {
      setFormValues({
        advertiserName: openLedgerBalanceModal?.data?.advertiserName || 'N/A',
        advertiserId: openLedgerBalanceModal?.data?.advertiserId || 'N/A',
        advertiserCountry: openLedgerBalanceModal?.data?.countryName || 'N/A',
        advertiserCurrency: openLedgerBalanceModal?.data?.currency || 'N/A',

      });
    }
  }, [openLedgerBalanceModal]);

  useEffect(() => {
    if (data) {
      setBalanceValues({
        openingBalance: data?.openingBalance,
        paymentIn: data?.paymentIn,
        paymentOut: data?.paymentOut,
        closingBalance: data?.closingBalance,
      });
    }
  }, [data]);

  const handleDownloadClick = (event) => {
    event.preventDefault(); // Prevent default behavior
    generatePDF(tableRows1, tableRows2, tableRows3);
  };

  const handleMonthChange = (newDate) => {
    if (newDate) {
      setSelectedMonth(newDate.format('YYYY-MM'));
    }
  };
  useEffect(() => {
    if (openLedgerBalanceModal?.open) {
      setSelectedMonth(moment().format('YYYY-MM'));
    }
  }, [advertiserId, openLedgerBalanceModal]);
  useEffect(() => {
    if (advertiserId && openLedgerBalanceModal?.open && selectedMonth) {
      getLedgerDetails(advertiserId, selectedMonth); // Fetch data with the selected month
    }
  }, [selectedMonth]);

  const tableRows1 = [
    { key: 'Advertiser Name', value: formValues?.advertiserName || 'N/A' },
    { key: 'Advertiser ID', value: formValues?.advertiserId || 'N/A' },
    { key: 'Advertiser Currency', value: formValues?.advertiserCurrency || 'N/A' },
    { key: 'Country', value: formValues?.advertiserCountry || 'N/A' },
  ];

  const tableRows2 = [
    { key: 'Opening Balance', value: formatCurrency(balanceValues?.openingBalance) },
    { key: 'Payments in', value: formatCurrency(balanceValues?.paymentIn) },
    { key: 'Payments out', value: formatCurrency(balanceValues?.paymentOut) },
    { key: 'Closing Balance', value: formatCurrency(balanceValues?.closingBalance) },
  ];

  const tableRows3 = (table3 && Array.isArray(table3)) ? table3.map((item, index) => ({
    // date: new Date(item.date).toLocaleDateString('en-GB') + ', ' + new Date(item.date).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: true }),
    date: moment(item?.date).format("DD/MM/YYYY, hh:mm A"),
    user: item?.userDetail?.name || 'Unknown',
    userEmail: item?.userDetail?.email || 'N/A',
    description: item?.description || 'No description',
    amount: item?.type === 'expense' ? `- ₹${item?.amount.toLocaleString('en-US')}` : item?.type === 'budget' ? `+ ₹${item?.amount.toLocaleString('en-US')}` : `₹${item?.amount.toLocaleString('en-US')}`,
    type: item?.type || 'Unknown',
    balance: item?.balance.toLocaleString('en-US') || 'N/A'
  })) : [];
  console.log("object 3", tableRows3);

  return (
    <div>

      <Modal open={openLedgerBalanceModal?.open} onClose={handleClose} sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        px: 10,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        opacity: 0.95,
      }}>

        <Box>
          <Box
            sx={{
              position: 'absolute',
              top: 18,
              right: 15,
              cursor: 'pointer',
            }}
            onClick={handleClose}
          >
            <img
              src={cancel}
              alt="cancel"
              className="h-10"
              onMouseOver={(e) => e.currentTarget.src = cancelHover}
              onMouseOut={(e) => e.currentTarget.src = cancel}
              onClick={handleClose}
            />
          </Box>
          <form>
            <div id="pdfTable" className='flex flex-col  h-[100dvh] py-5'>
              <div className='flex gap-5 justify-between'>
                <TableContainer component={Paper} sx={{ mb: 2, width: '425px', border: '1px solid #5B5B5B', borderRadius: '10px' }}>
                  <Table>
                    <TableHead sx={{ bgcolor: '#C7EAE9' }}>
                      <TableRow>
                        <TableCell colSpan={2} sx={{ paddingY: '7px', fontWeight: 600, fontSize: '20px', borderBottom: '1px solid black' }}>Advertiser Details</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableRows1.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell sx={{ paddingY: '5px', width: '40%', borderRight: '1px solid black', textAlign: 'left', fontWeight: 500, fontSize: '14px', overflow: 'auto' }}>{row.key}</TableCell>
                          <TableCell sx={{ paddingY: '5px', width: '60%', textAlign: 'left', fontWeight: 500, fontSize: '14px', overflow: 'auto' }}>{row.value}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TableContainer component={Paper} sx={{ mb: 2, width: '425px', border: '1px solid #5B5B5B', borderRadius: '10px' }}>
                  <Table>
                    <TableHead sx={{ bgcolor: '#C7EAE9' }}>
                      <TableRow>
                        <TableCell colSpan={2} sx={{ paddingY: '7px', fontWeight: 600, fontSize: '20px', borderBottom: '1px solid black' }}>Account Summary</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableRows2.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell sx={{ paddingY: '5px', width: '40%', borderRight: '1px solid black', textAlign: 'left', fontWeight: 500, fontSize: '14px', overflow: 'auto' }}>{row.key}</TableCell>
                          <TableCell sx={{ paddingY: '5px', width: '60%', textAlign: 'left', fontWeight: 500, fontSize: '14px', overflow: 'auto' }}> ₹{row.value}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

              </div>
              <div className='w-full flex justify-between mt-2 mb-2'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    // label="Choose Month"
                    value={selectedMonth ? dayjs(selectedMonth) : null}
                    // value={selectedMonth}
                    onChange={handleMonthChange}
                    disabled={isDatePickerDisabled}
                    views={['month', 'year']}
                    format="YYYY-MM"
                    disableFuture
                    renderInput={(params) => (
                      <input
                        {...params}
                        style={{
                          color: 'white',
                          backgroundColor: 'blue',
                          border: '1px solid gray',
                          borderRadius: '8px',
                          padding: '8px',
                          fontSize: '16px',
                        }}
                      />
                    )}
                    sx={{
                      '& .MuiInputBase-input': {
                        color: 'black', // Text color inside picker
                      },
                      '& .MuiOutlinedInput-root': {
                        backgroundColor: 'white',
                        borderRadius: '10px',
                        border: '1px solid #1C4546',
                        height: '45px',
                        width: '150px'
                      },
                      '& .MuiButtonBase-root': {
                        color: '#1C4546', // Button color (for navigation buttons)
                      },
                    }}
                  />
                </LocalizationProvider>

                <button
                  className='flex px-3 py-0.5 h-11 border border-transparent rounded-lg items-center justify-center bg-primaryColor text-lg font-medium text-white'
                  onClick={handleDownloadClick} // Ensure onClick is bound here
                >
                  <img src={download} alt='download' className='h-8' />&nbsp;Download
                </button>
              </div>

              <div>
                <TableContainer id="pdfTable" component={Paper} sx={{ mb: 2, width: '100%', maxHeight: 'calc(100vh - 320px)', overflowY: 'auto', border: '1px solid #363636', borderRadius: '10px' }}>
                  <Table>
                    <TableHead sx={{ bgcolor: '#C7EAE9' }}>
                      <TableRow>
                        <TableCell sx={{ paddingY: '12px', height: '40px', maxWidth: '15%', borderRight: '1px solid black', fontWeight: 600, fontSize: '20px', borderBottom: '1px solid black', textAlign: 'center' }}>Date</TableCell>
                        <TableCell sx={{ paddingY: '12px', width: '15%', maxWidth: '100px', borderRight: '1px solid black', fontWeight: 600, fontSize: '20px', borderBottom: '1px solid black', textAlign: 'center' }}>User</TableCell>
                        <TableCell sx={{ paddingY: '12px', width: '20%', maxWidth: '200px', borderRight: '1px solid black', fontWeight: 600, fontSize: '20px', borderBottom: '1px solid black', textAlign: 'center' }}>Description</TableCell>
                        <TableCell sx={{ paddingY: '12px', width: '10%', maxWidth: '10%', borderRight: '1px solid black', fontWeight: 600, fontSize: '20px', borderBottom: '1px solid black', textAlign: 'center' }}>Amount</TableCell>
                        <TableCell sx={{ paddingY: '12px', width: '10%', maxWidth: '10%', borderRight: '1px solid black', fontWeight: 600, fontSize: '20px', borderBottom: '1px solid black', textAlign: 'center' }}>Balance</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {loading ? (
                        <TableRow>
                          <TableCell colSpan={6} sx={{ textAlign: 'center' }}><CircularProgress /></TableCell>
                        </TableRow>
                      ) : (
                        tableRows3?.length > 0 ? (
                          tableRows3?.map((row, index) => (
                            <TableRow key={index}
                              sx={{
                                backgroundColor: row.type === 'mediaExpense' ? '#FFAEAE' : row.type === 'additionalExpense' ? '#FFCCB0' : row.type === 'ClosingAmount' ? '#C0FFCD' : "",
                              }}>
                              <TableCell sx={{ paddingY: '5px', height: '40px', width: '15%', borderRight: '1px solid black', textAlign: 'center', fontWeight: 500, fontSize: '14px', whiteSpace: 'normal', wordWrap: 'break-word' }}>{row.date}</TableCell>
                              <TableCell sx={{ paddingY: '5px', height: '40px', width: '100px', borderRight: '1px solid black', textAlign: 'center', fontWeight: 500, fontSize: '14px', whiteSpace: 'normal', wordWrap: 'break-word' }}>{row.user}
                                {/* <p className='text-xs pt-1 opacity-90'>{row.userEmail}</p> */}
                              </TableCell>
                              <TableCell sx={{ paddingY: '5px', height: '40px', maxWidth: '200px', borderRight: '1px solid black', textAlign: 'left', fontWeight: 500, fontSize: '14px', whiteSpace: 'normal', wordWrap: 'break-word' }}>{row.description}</TableCell>
                              <TableCell sx={{
                                paddingY: '5px', height: '40px', width: '10%', borderRight: '1px solid black', textAlign: 'center', fontWeight: 500, fontSize: '14px',
                                color: row.type === 'expense' ? 'red' : row.type === 'budget' ? 'green' : 'black', whiteSpace: 'normal', wordWrap: 'break-word'
                              }}>{`${row.amount}`}</TableCell>
                              <TableCell sx={{ paddingY: '5px', height: '40px', width: '10%', borderRight: '1px solid black', textAlign: 'center', fontWeight: 500, fontSize: '14px', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                {(row?.type === "budget" || row?.type === "expense") ? `₹${row?.balance}` : ""}</TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={6} align="center">No Data Available</TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>

                  </Table>
                </TableContainer>

              </div>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );

};

export default LedgerBalance;