import { FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import React, { useEffect } from "react";
import device from "../../assets/icons/device.png"
const DeviceList = ({ selectedDevice, setSelectedDevice, advertiserId }) => {

    useEffect(() => {
        setSelectedDevice("");
    }, [advertiserId]);

    const handleDevice = (e) => {
        const device = e.target.value;
        setSelectedDevice(device);
        console.log("Selected device:", device);
    };

    return (
        <div className="lg:min-w-40 max-h-5">
            <FormControl fullWidth>
                <Select
                    labelId="device-select-label"
                    id="device-select"
                    value={selectedDevice || ""}
                    displayEmpty
                    onChange={handleDevice}
                    input={
                        <OutlinedInput
                            startAdornment={
                                <InputAdornment position="start">
                                    <img
                                        src={device}
                                        alt="device"
                                        className="mr-3 pb-0.5"
                                    />
                                </InputAdornment>
                            }
                            sx={{
                                height: "55px",
                                width: "190px",
                                backgroundColor: "white",
                                color: "#1c4546",
                                borderRadius: "10px",
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#1c4546", // Full-time #1c4546 border
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#1c4546", // Maintain #1c4546 border on hover
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#1c4546", // Keep #1c4546 border when focused
                                },
                                "& .MuiSvgIcon-root": { color: "#1c4546" }
                            }}
                        />
                    }
                >
                    <MenuItem value="">Select Device</MenuItem>
                    <MenuItem value="Smart Phone">Mobile</MenuItem>
                    <MenuItem value="Desktop">Desktop</MenuItem>
                    <MenuItem value="Connected TV">Connected TV</MenuItem>
                    <MenuItem value="others">Others</MenuItem>
                </Select>
            </FormControl>
        </div>
    );
};

export default DeviceList;

