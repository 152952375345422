/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { MultiSelect } from "primereact/multiselect";
import Close from "../../assets/icons/close.svg";
import { Link } from "react-router-dom";

const PopUp = ({
  setPopup,
  popup,
  selectedOptions,
  entityTypeData,
  setShowFilterGroup,
  setSelectedOptions,
  saveData,
}) => {
  const panelFooterTemplate = () => {
    const length = entityTypeData?.entities
      ? entityTypeData?.entities.length
      : 0;
    setShowFilterGroup(true);

    return (
      <div className="py-2 px-3">
        <b>{length}</b> item{length > 1 ? "s" : ""} Available.
      </div>
    );
  };


  const handleOptionClick = (option) => {
    setSelectedOptions(option.value);
    setShowFilterGroup(true);
  };

  const ClearSelectedData = () => {
    setSelectedOptions([]);
  };
  const countryTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.name}
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`mr-2 flag flag-${option.code}`}
          style={{ width: "18px" }}
        />
        <div>{option.name}</div>
        <div>{option.id}</div>
      </div>
    );
  };

  return (
    <div className="modal-popup">
      <div className="header flex align-centery">
        <div className="close" onClick={() => setPopup(!popup)}>
          <img src={Close} alt="" style={{ cursor: "pointer" }} />
        </div>
        <p>Select partners and advertisers</p>
      </div>
      <div className="modal-box">
        <div className="flex align-top gap">
          <MultiSelect
            value={selectedOptions}
            options={entityTypeData?.entities}
            onChange={(e) => handleOptionClick(e)}
            optionLabel="name"
            placeholder="Search..."
            itemTemplate={countryTemplate}
            panelFooterTemplate={panelFooterTemplate}
            className="w-full md:w-20rem"
            display="chip"
          />

          <div className="right-modal">
            <div className="modal-head flex align-center justify-space">
              <span>
                {selectedOptions?.length ? selectedOptions?.length : 0} selected
              </span>
              <a className="hover:text-primaryColor cursor-pointer hover:no-underline" onClick={ClearSelectedData}>
                CLEAR ALL
              </a>
            </div>
            {selectedOptions?.length ? (
              <div className="right-body">
                <ul className="list-none">
                  <li>
                    <ul className="list-none">
                      {selectedOptions?.map((option) => (
                        <li>
                          <div className="flex align-center justify-space">
                            <span>{option.name}</span>
                            <span>{option.id}</span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

      </div>

      <div className="mt-20">
        <button className="font-normal px-5 py-2 rounded-[7px] text-white bg-primaryColor border border-primaryColor hover:text-primaryColor hover:bg-white" onClick={saveData}>
          SELECT
        </button>
        <button
          className=" ml-5 font-normal px-5 py-2 rounded-[7px] hover:text-white hover:bg-primaryColor border border-primaryColor text-primaryColor bg-white"
          onClick={() => setPopup(!popup)}
        >
          CANCEL
        </button>
      </div>
    </div>
  );
};

export default PopUp;
