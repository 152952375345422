import React, { useState, useEffect } from 'react';
import { Box, Modal, TextField, Button, Typography, MenuItem, Chip, CircularProgress, Autocomplete } from '@mui/material';
import useToast from '../Hooks/Toast';
import axios from 'axios';
import { cookieGetter } from '../utils/HelperFunction';
import { useNavigate } from 'react-router-dom';
import cancelHover from '../../assets/icons/delete (6) 1.png'
import cancel from '../../assets/icons/delete (7) 1.png'
import { countries, currencies, industries } from '../utils/constants';
import useAdvertiser from './useAdvertiser';
import { formatNumberWithCommas } from '../utils/currencyFormatter';

const AddAdvertiserModal = ({ open, handleClose, initialData, openAdvertiserModal }) => {

  const { advertiserApiCall } = useAdvertiser(handleClose);
  const navigate = useNavigate();
  const appIdToken = cookieGetter("appIdToken");
  const notify = useToast();
  const [emails, setEmails] = useState([]);
  const [emailInput, setEmailInput] = useState();
  const [loading, setLoading] = useState(false);
  const [emailSuggestions, setEmailSuggestions] = useState([]);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');

  const [formValues, setFormValues] = useState({
    advertiserName: '',
    advertiserId: '',
    countryName: '',
    industry: '',
    currency: '',
    initialBudget: '',
    dailyBudget: '',
  });
  useEffect(() => {
    if (initialData) {
      setFormValues({
        id: initialData?.id || '',
        advertiserName: initialData?.advertiserName || '',
        advertiserId: initialData?.advertiserId || '',
        countryName: initialData?.countryName || '',
        industry: initialData?.industry || '',
        currency: initialData?.currency || '',
        initialBudget: formatNumberWithCommas(initialData?.lifetimeBudget || ''),
        dailyBudget: formatNumberWithCommas(initialData?.dailyBudget || ''),
      });

      // Initialize emails if initialData has emails
      let emails = initialData?.emails?.split(',').map(email => email.trim())
      setEmails(emails || []);
    } else {
      // Reset formValues and emails if initialData is not provided (add new)
      setFormValues({
        advertiserName: '',
        advertiserId: '',
        countryName: '',
        industry: '',
        currency: '',
        initialBudget: '',
        dailyBudget: '',
      });
      setEmails([]);
    }
  }, [initialData]);

  const removeCommas = (str) => {
    return str.replace(/,/g, '');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    const rawValue = removeCommas(value);
    const formattedValue = name.includes('Budget') ? formatNumberWithCommas(rawValue) : value;

    setFormValues((prev) => ({
      ...prev,
      [name]: formattedValue,
    }));
  };

  const handleEmailInputChange = (e) => {
    const inputValue = e.target.value;
    setEmailInput(inputValue);

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = regex.test(inputValue);
    setIsValidEmail(isValid);

    if (!isValid) {
      setEmailSuggestions([]);
    } else {
      const domain = inputValue.split('@')[1];
      const suggestions = ['gmail.com', 'yahoo.com', 'outlook.com']; // Mock suggestions
      setEmailSuggestions(suggestions.filter((suggestion) => suggestion.startsWith(domain)));
    }
  };

  const handleEmailInputKeyDown = (e) => {
    if (e.key === 'Enter' && emailInput && isValidEmail) {
      e.preventDefault(); // Prevent form submission
      if (emails.length < 10) {
        setEmails((prev) => [...prev, emailInput]); // Add new email to emails array
        setEmailInput('');
        setEmailSuggestions([]);
      } else {
        alert("Maximum limit is 10");
      }
    }
  };

  const handleDeleteEmail = (emailToDelete) => () => {
    setEmails((prev) => prev.filter((email) => email !== emailToDelete));
  };

  const handleKeyDown = (e) => {
    const allowedKeys = [
      'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab',
      'Control', 'v', 'V', 'c', 'C', 'a', 'A', '.'
    ];

    const isCtrlKey = e.ctrlKey || e.metaKey; // For Mac users using Command key

    if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key) && !isCtrlKey) {
      e.preventDefault();
    }
    if (e.key === '.' && e.target.value.includes('.')) {
      e.preventDefault();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const finalObject = {
      ...formValues,
      initialBudget: parseFloat(removeCommas(formValues.initialBudget)) || 0,
      dailyBudget: parseFloat(removeCommas(formValues.dailyBudget)) || 0,
      emails: emails.toString(),
    };

    await advertiserApiCall(finalObject, openAdvertiserModal?.data?.id)
      .then(() => {
        setLoading(false);
        window.location.reload();
      });
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          height: '100%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          px: 24,
          py: 4,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          opacity: 0.90,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 25,
            right: 66,
            cursor: 'pointer',
          }}
          onClick={handleClose}
        >
          <img
            src={cancel}
            alt="cancel"
            className="h-10"
            onMouseOver={(e) => e.currentTarget.src = cancelHover}
            onMouseOut={(e) => e.currentTarget.src = cancel}
            onClick={handleClose}
          />
        </Box>
        <form onSubmit={handleSubmit} >
          <Typography className='text-xs' variant="h4" gutterBottom>
            <div className='text-base font-semibold'>Advertiser Details</div>
          </Typography>
          <div className='border border-[#B5B5B5] px-16 pt-6 '>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <TextField
                id="outlined-basic"
                label="Advertiser Name"
                name="advertiserName"
                value={formValues.advertiserName}
                onChange={handleChange}
                variant="outlined"
                required
                fullWidth
                disabled={initialData ? true : false}
              />
              <TextField
                id="outlined-basic"
                label="Advertiser ID"
                name="advertiserId"
                value={formValues.advertiserId}
                onChange={handleChange}
                variant="outlined"
                required
                fullWidth
                disabled={initialData ? true : false}
              />
            </Box>
            <TextField
              id="outlined-basic"
              label="Advertiser Email"
              name="advertiserEmail"
              value={emailInput}
              // defaultValuevalue={initialData ? emails : emailInput}
              onChange={handleEmailInputChange}
              onKeyDown={handleEmailInputKeyDown}
              error={!isValidEmail}
              helperText={!isValidEmail && 'Please enter a valid email'}
              variant="outlined"
              required={!emails.length}
              fullWidth
              margin="normal"
            />
            <p className='text-xs text-gray-400'>(press enter to save email id)</p>

            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
              {emails.map((email, index) => (
                <Chip
                  key={index}
                  label={email}
                  onDelete={handleDeleteEmail(email)}
                  style={{ marginRight: '8px', marginBottom: '8px' }}
                />
              ))}
            </Box>
          </div>

          <Box sx={{ display: 'flex', gap: 2, mt: 4, flexDirection: 'column' }}>
            <Typography variant="h4" gutterBottom>
              <div className='text-base font-semibold'>Business Details</div>
            </Typography>
            <div className='border border-[#B5B5B5] px-16 py-7 w-full'>

              <Box sx={{ display: 'flex', gap: 2 }}>
                <Autocomplete
                  id="country-name"
                  disabled={initialData ? true : false}
                  options={countries}
                  getOptionLabel={(option) => option.label}
                  value={countries.find((c) => c.label === formValues?.countryName) || null}
                  onChange={(event, newValue) => {
                    handleChange({ target: { name: 'countryName', value: newValue?.label || '' } });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country Name"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                  style={{ width: '100%' }}
                  // You can control the dropdown max height
                  PaperComponent={({ children }) => (
                    <div style={{ backgroundColor: 'white' }}>{children}</div>
                  )}
                />
                <Autocomplete
                  id="industry-select"
                  disabled={initialData ? true : false}
                  options={industries}
                  getOptionLabel={(option) => option}
                  value={industries.find((c) => c === formValues.industry) || null}
                  onChange={(event, newValue) => {
                    setFormValues((prev) => ({
                      ...prev,
                      industry: newValue || '',
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Industry"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                  style={{ width: '100%' }}
                  PaperComponent={({ children }) => (
                    <div style={{ backgroundColor: 'white' }}>{children}</div>
                  )}
                />

                <Autocomplete
                  id="currency-select"
                  disabled={initialData ? true : false}
                  options={currencies}
                  getOptionLabel={(option) => `${option.symbol} - ${option.code} - ${option.label}`}
                  value={currencies.find((c) => c.code === formValues.currency) || null}
                  onChange={(event, newValue) => {
                    setFormValues((prev) => ({
                      ...prev,
                      currency: newValue?.code || '',
                      currencySymbol: newValue?.symbol || '',
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Currency"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                  style={{ width: '100%' }}
                  PaperComponent={({ children }) => (
                    <div style={{ backgroundColor: 'white' }}>{children}</div>
                  )}
                />
              </Box>
            </div>
          </Box>

          <Box sx={{ display: 'flex', gap: 2, mt: 4, flexDirection: 'column' }}>
            <Typography variant="h4" gutterBottom>
              <div className='text-base font-semibold'>Budget Details</div>
            </Typography>
            <div className='border border-[#B5B5B5] px-16 py-4 w-full'>

              <Box sx={{ display: 'flex', gap: 2 }}>
                <TextField
                  id="outlined-basic"
                  label="Initial Budget"
                  name="initialBudget"
                  value={formValues.initialBudget}
                  // defaultValue={0}
                  onChange={(e) => handleChange(e)}
                  onKeyDown={handleKeyDown}
                  onPaste={(e) => {
                    const paste = (e.clipboardData || window.clipboardData).getData('text');
                    if (!/^\d*\.?\d*$/.test(paste)) {
                      e.preventDefault();
                    }
                  }}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  disabled={initialData ? true : false}

                />
                <TextField
                  id="outlined-basic"
                  label="Daily Cost"
                  name="dailyBudget"
                  value={formValues.dailyBudget}
                  // defaultValue={0}
                  onChange={(e) => handleChange(e)}
                  onKeyDown={handleKeyDown}
                  onPaste={(e) => {
                    const paste = (e.clipboardData || window.clipboardData).getData('text');
                    if (!/^\d*\.?\d*$/.test(paste)) {
                      e.preventDefault();
                    }
                  }}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              </Box>
            </div>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, gap: 4 }}>
            <Button
              variant="outlined"
              sx={{
                borderColor: '#1C4645',
                color: '#1C4645',
                '&:hover': {
                  backgroundColor: '#1C4645',
                  color: 'white',
                  borderColor: '#1C4645',
                },
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="outlined"
              sx={{
                backgroundColor: '#1C4645',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'white',
                  borderColor: '#1C4645',
                  color: '#1C4645',
                },
                ml: 2,
              }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} color="inherit" sx={{ color: 'white' }} /> : 'Save'}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default AddAdvertiserModal;