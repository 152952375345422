import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts'; // Default fonts

pdfMake.vfs = pdfFonts.pdfMake.vfs; // Load default fonts

const generatePDF = async (tableRows1, tableRows2, tableRows3) => {
  const margin = 3;
  const lineHeight = 6;

  const pdfName = prompt('Enter the name for your PDF:', 'table-data');
  if (!pdfName) return; // If no name is entered, exit the function

  // Function to determine row background color
  const getRowColor = (type) => {
    if (type === 'mediaExpense') return '#FFAEAE';
    if (type === 'additionalExpense') return '#FFCCB0';
    if (type === 'ClosingAmount') return '#C0FFCD';
    return ''; // Default color if no condition matches
  };

  // Define document content
  const docDefinition = {
    content: [
      { text: 'Advertiser Details', margin: [margin, 0, 0, lineHeight], fontSize: 12, bold: true },
      {
        text: tableRows1.map(row => `${row.key} : ${row.value}`).join('\n'),
        margin: [margin, 0, 0, lineHeight],
        fontSize: 10
      },
      { text: '', margin: [0, 10, 0, 10], border: [false, false, false, true] }, // Horizontal line
      { text: 'Account Summary', margin: [margin, lineHeight, 0, lineHeight], fontSize: 12, bold: true },
      {
        text: tableRows2.map(row => `${row.key} : ₹${row.value}`).join('\n'),
        margin: [margin, 0, 0, lineHeight],
        fontSize: 10
      },
      { text: '', margin: [0, lineHeight, 0, lineHeight], border: [false, false, false, true] }, // Horizontal line
      { text: 'Ledger Balance', margin: [margin, lineHeight, 0, lineHeight - 2], fontSize: 12, bold: true },
      {
        table: {
          headerRows: 1,
          widths: [100, 80, 150, 70, 70], // Adjust column widths as needed
          body: [
            [
              { text: 'Date', style: 'tableHeader', fontSize: 10, paddingLeft: 3 },
              { text: 'User', style: 'tableHeader', fontSize: 10 },
              { text: 'Description', style: 'tableHeader', fontSize: 10 },
              { text: 'Amount', style: 'tableHeader', fontSize: 10 },
              { text: 'Balance', style: 'tableHeader', fontSize: 10 }
            ],
            ...tableRows3.map(row => [
              { text: row.date, fillColor: getRowColor(row.type), fontSize: 8, margin: [0, 4] },
              { text: row.user, fillColor: getRowColor(row.type), fontSize: 8, margin: [0, 4] },
              { text: row.description, fillColor: getRowColor(row.type), fontSize: 8, margin: [0, 4] },
              {
                text: `${row.amount}`,
                color: row.type === 'expense' ? 'red' : row.type === 'budget' ? 'green' : 'black', // Conditional font color
                fillColor: getRowColor(row.type),
                margin: [0, 4], // Vertical padding
                fontSize: 8,
              },
              {
                text: (row.type === 'budget' || row.type === 'expense') ? `₹ ${row.balance}` : '',
                // color: row.type === 'expense' ? 'red' : row.type === 'budget' ? 'green' : 'black', // Conditional font color
                fillColor: getRowColor(row.type),
                fontSize: 8,
                margin: [0, 4] // Vertical padding
              }
            ])
          ]
        },
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return null;
          },
          hLineColor: function (i, node) {
            return i === 0 || i === node.table.body.length ? 'black' : 'gray';
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 'black' : 'gray';
          },
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 1 : 1;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 1 : 1;
          },
        }
      }
    ],
    styles: {
      tableHeader: {
        bold: true,
        fontSize: 10,
        color: 'white',
        fillColor: 'black' // Black background color for the header
      },
      tableData: {
        fontSize: 8
      }
    }
  };

  // Generate PDF
  pdfMake.createPdf(docDefinition).download(`${pdfName}.pdf`);
};

export default generatePDF;


// import jsPDF from 'jspdf';

// const generatePDF = async (tableRows1, tableRows2, tableRows3) => {

//   const pdf = new jsPDF('p', 'mm', 'a4');
//   const lineHeight = 8; // Line height for text
//   let yPosition = 10; // Initial Y position for the first text
//   const margin = 10;

//   const pdfName = prompt('Enter the name for your PDF:', 'table-data');
//   if (!pdfName) return; // If no name is entered, exit the function

//   const columnWidths = [40, 35, 50, 25, 30]; // Adjust column widths as needed

//   // Set font size
//   pdf.setFontSize(10);
//   yPosition += tableRows1.length;

//   // Add Advertiser Details section
//   pdf.text('Advertiser Details', margin, yPosition);
//   yPosition += lineHeight;
//   const table1Content = tableRows1.map(row => `${row.key}: ${row.value}`).join('\n');
//   pdf.text(table1Content, margin, yPosition);
//   yPosition += tableRows1.length * lineHeight; // Adjust Y position

//   // Add a line after Advertiser Details section
//   pdf.line(margin, yPosition - lineHeight, 200, yPosition - lineHeight);

//   // Add Account Summary section
//   pdf.text('Account Summary', margin, yPosition);
//   yPosition += lineHeight;
//   const table2Content = tableRows2.map(row => `${row.key}: ${row.value}`).join('\n');
//   pdf.text(table2Content, margin, yPosition);
//   yPosition += tableRows2.length * lineHeight; // Adjust Y position

//   // Add a line after Account Summary section
//   pdf.line(margin, yPosition - lineHeight, 200, yPosition - lineHeight);

//   // Add Ledger Table section
//   pdf.text('Ledger Balance', margin, yPosition);
//   yPosition += lineHeight;

//   // Define table headers
//   const headers = [['Date', 'User', 'Description', 'Amount', 'Balance']];

//   // Define table rows
//   const rows = tableRows3.map(row => [
//     row.date,
//     `${row.user}`,
//     // \n${row.userEmail}
//     row.description,
//     {
//       content: row.amount,
//       styles: {
//         cellWidth: 30,
//         textColor: row.type === 'expense' ? [255, 0, 0] : "#006400" // Conditional font color
//       }
//     },
//     // row.amount,
//     row.balance
//   ]);

//   // Generate table
//   pdf.autoTable({
//     startY: yPosition,
//     head: headers,
//     body: rows,
//     theme: 'grid',
//     headStyles: { fillColor: [0, 0, 0] }, // Black header background
//     styles: { overflow: 'linebreak', cellPadding: 2, fontSize: 8 }, // Adjust styles as needed
//     columnStyles: {
//       0: { cellWidth: columnWidths[0] },
//       1: { cellWidth: columnWidths[1] },
//       2: { cellWidth: columnWidths[2] },
//       3: { cellWidth: columnWidths[3] },
//       4: { cellWidth: columnWidths[4] }
//     },
//     didDrawCell: (data) => {
//       if (data.column.index === 3) { // Apply conditional styling to the 'Amount' column
//         const row = tableRows3[data.row.index];
//         pdf.setTextColor(row.type === 'expense' ? 255 : 0, row.type === 'expense' ? 0 : 255, 0); // Set color based on type
//       }
//     }
//   });
//   // pdf.save('table-data.pdf');
//   pdf.save(`${pdfName}.pdf`);

// };

// export default generatePDF