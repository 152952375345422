import React from "react";
import {useConfigureContext} from "../Context/ConfigureContext"
const AppOptionGroupComponent = (props) => {
  const {
    AppOptionGroup,
    filterGroups,
    setFilterGroups,
    setDimensionFilterGroup,
    setAppOptionGroup,
    setEmailEnable,
    setFinalSaveEnable
  } = useConfigureContext();

  const handleAddFilterGroup = () => {
    const type = filterGroups.map((type) =>
      type.type === "dimension" ? "dimension" : "metric"
    );

    const lastType = type[type.length - 1];
    const newFilterGroup = {
      type: lastType, 
      id : `${lastType}-${filterGroups?.length + 1}`
    };
    setFilterGroups([...filterGroups, newFilterGroup]);

  };
  const handleSaveFilterChanges = () => {
    setEmailEnable(true);
    setFinalSaveEnable(true)
  };
  const handleCancelFilterGroup = () => {
    setFilterGroups([]); // Clear the filter groups
    setDimensionFilterGroup(false); // Hide the filter group section
    setAppOptionGroup(false);
    setEmailEnable(false);
  };

  return (
    <>
      {AppOptionGroup && filterGroups?.length ? (
        <div className="summary shadow bg-white m-0 flex align-center justify-space additional-buttons">
          <div>
            <button className="button" onClick={handleAddFilterGroup}>
              Add More Filter
            </button>
          </div>
          <div className="flex align-center">
            <button
              className="delete"
              onClick={() => handleCancelFilterGroup()}
            >
              Cancel
            </button>
            <button
              className="button"
              onClick={() => handleSaveFilterChanges()}
            >
              Save Changes
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default AppOptionGroupComponent;
