// import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
const { default: jwt_decode } = require("jwt-decode");
export const getEmailFromToken = (token) => {
    try {
        // Decode the JWT token
        const decoded = jwt_decode(token);
        Cookies.set("email", decoded.email);
        Cookies.set("role", decoded.role);

        console.log("email and role of logined user is set in cookiee")
    } catch (error) {
        console.error('Error decoding JWT token:', error);
        return null;
    }
};
