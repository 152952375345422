import React from 'react';

const LevelOfAlert = ({ alertLevel }) => {

    const renderShape = () => {
        switch (alertLevel) {
            case 'GENERAL':
                return (
                    <svg width="30" height="30">
                        <circle
                            cx="15"
                            cy="15"
                            r="10"
                            fill="green"
                            stroke="black"
                        />
                    </svg>
                );
            case 'LOW':
                return (
                    <svg width="30" height="30">
                        <polygon
                            points="15,5 25,25 5,25"
                            fill="orange"
                        // stroke="black"
                        />
                    </svg>
                );
            case 'MODERATE':
                return (
                    <svg width="30" height="30">
                        <rect
                            x="7.5"
                            y="7.5"
                            width="15"
                            height="15"
                            fill="yellow"
                        // stroke="black"
                        />
                    </svg>
                );
            case 'HIGH':
                return (
                    <svg width="30" height="30" viewBox="0 0 30 30">
                        <polygon
                            points="15,4 18,11 25,11 19,15 21,22 15,18 9,22 11,15 5,11 12,11"
                            fill="red"
                        // stroke="black"
                        />
                    </svg>
                );
            default:
                return (
                    <svg width="30" height="30">
                        <circle
                            cx="15"
                            cy="15"
                            r="10"
                            fill="green"
                        // stroke="black"
                        />
                    </svg>
                );
        }
    };

    return (
        renderShape()
    );
};

export default LevelOfAlert;
