import React, { useState } from "react";
import Group from "./group";
import {useConfigureContext} from "../Context/ConfigureContext"

const FilterBar = (props) => {
  const {
    dimensionFilterGroup,
    preFilledData,
    editConfigation,
    filterGroups,
    setFilterGroups,
  } = useConfigureContext();;

  const [isActiveDimension, setIsActiveDimension] = useState(false);
  const [isActiveFilterGroup, setIsActiveFilterGroup] = useState(false);

  
  const handleDeleteFilterGroup = (index, id) => {
    const tmpFilterGrp = filterGroups?.filter((item) => {
      return item.id !== id
    })
    setFilterGroups(tmpFilterGrp);
  };

  return (
    <>
      {dimensionFilterGroup ||
      preFilledData.length > 0 ||
      editConfigation.length > 0 ? (
        <>
          {filterGroups?.map((filterGroup, index) => (
            <Group
              key={index}
              filterGroup={filterGroup}
              index={index}
              onDelete={() => handleDeleteFilterGroup(index, filterGroup.id)}
              isActiveFilterGroup={isActiveFilterGroup}
              setIsActiveFilterGroup={setIsActiveFilterGroup}
              isActiveDimension={isActiveDimension}
              setIsActiveDimension={setIsActiveDimension}
            />
          ))}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default FilterBar;
