import React, { useEffect, useState, useRef } from "react";
import { WorldMap } from "react-svg-worldmap";
import countries from "i18n-iso-countries";
import UseHistogramData from "../Hooks/Dashboard/useHistogram";
import { CircularProgress, Select, MenuItem, FormControl } from "@mui/material";
import { formatValue } from "../utils/currencyFormatter";
import UseCurrencyConverter from "../Hooks/useCurrencyConverter";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const Histogram = ({ selectedDateRange, advertiserId, selectedCurrency }) => {
    const [selectedMetric, setSelectedMetric] = useState("impressions");
    const [currentMetricData, setCurrentMetricData] = useState([]);
    const { histogramApiCall, loading, histogramData } = UseHistogramData();
    const { convertAmount } = UseCurrencyConverter();

    const leftTableRef = useRef(null);
    const rightTableRef = useRef(null);

    const handleScroll = (e) => {
        if (e.target === leftTableRef.current) {
            rightTableRef.current.scrollTop = e.target.scrollTop;
        } else if (e.target === rightTableRef.current) {
            leftTableRef.current.scrollTop = e.target.scrollTop;
        }
    };
    const histogramObject = {
        startDate: selectedDateRange?.startDate,
        endDate: selectedDateRange?.endDate,
        advertiserId: advertiserId,
    };

    useEffect(() => {
        if (advertiserId && selectedDateRange?.startDate && selectedDateRange?.endDate) {
            histogramApiCall(histogramObject);
        }
    }, [advertiserId, selectedDateRange]);

    useEffect(() => {
        setCurrentMetricData(histogramData?.[selectedMetric] || []);
    }, [histogramData, selectedMetric])
    // const currentMetricData = histogramData?.[selectedMetric] || [];
    const mappedData = currentMetricData?.map((entry) => ({
        country: entry.country.toUpperCase(),
        value: entry.value,
    }));

    const getStyle = ({
        countryValue,
        minValue,
        maxValue,
    }) => ({
        fill: "red",
        fillOpacity: minValue === maxValue && countryValue ? 1 : countryValue
            ? 0.1 + (1.5 * (countryValue - minValue)) / (maxValue - minValue)
            : 0,
        stroke: "black",
        strokeWidth: 1,
        strokeOpacity: 0.2,
        cursor: "pointer",
    });
    const handleMetricChange = (event) => {
        setSelectedMetric(event.target.value);
    };

    if (!histogramData) {
        return (
            <div>
                <div className="font-medium text-xl pb-0 my-7 text-left">Histogram</div>
                <div className="flex flex-col justify-center items-center gap-3">
                    <CircularProgress size={40} color="inherit" sx={{ color: "#1c4546" }} />
                    <div>Loading...</div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex justify-between max-lg:flex-col-reverse gap-10">
            <div>
                <div className="font-medium text-xl pb-0 mb-5">Histogram</div>
                <div className="border border-black rounded-[10px] p-5">
                    <WorldMap
                        borderColor="red"
                        backgroundColor="transparent"
                        frameColor="#000"
                        value-suffix="units"
                        size="xl"
                        data={mappedData}
                        styleFunction={getStyle}
                    />
                </div>
            </div>
            <div className="xl:mr-8 2xl:mr-[110px]">
                <div className="font-medium text-xl pb-0 mb-5">Select the metric</div>
                <div className="border border-[#1C4645] rounded-[10px] max-h-[620px] overflow-hidden">
                    <table className="w-[100.70%] 2xl:w-[100%]" style={{ borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th
                                    className="font-medium text-[#1C4645] px-2 py-2"
                                    style={{
                                        height: '40px',
                                        backgroundColor: '#fff',
                                        borderBottom: '2px solid #1C4645',
                                        borderRight: '1px solid #1C4645',
                                        width: '200px',
                                    }}
                                >
                                    Country
                                </th>
                                <th
                                    className="font-medium px-2"
                                    style={{
                                        height: '40px',
                                        backgroundColor: '#fff',
                                        borderBottom: '2px solid #1C4645',
                                        width: '150px',
                                    }}
                                >
                                    <FormControl
                                        variant="outlined"
                                        sx={{
                                            maxWidth: '120px',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        <Select
                                            value={selectedMetric}
                                            onChange={handleMetricChange}
                                            displayEmpty
                                            sx={{
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    border: 'none',
                                                },
                                                '.MuiSelect-select': {
                                                    padding: '4px',
                                                    textAlign: 'center',
                                                },
                                                '.MuiSelect-icon': {
                                                    color: '#1c4546',
                                                },
                                            }}
                                        >
                                            {Object.keys(histogramData)?.map((metric) => (
                                                <MenuItem key={metric} value={metric}>
                                                    {metric === "revenue" ? "Cost" : metric === "cpc" ? "CPC" : metric.replace('_', ' ').charAt(0).toUpperCase() + metric.replace('_', ' ').slice(1)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </th>
                            </tr>
                        </thead>
                    </table>
                    <div style={{ maxHeight: '480px', overflowY: 'auto' }}>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <tbody>
                                {currentMetricData?.map((entry) => (
                                    <tr key={entry.country} style={{ textAlign: 'center' }}>
                                        <td
                                            className="border-t border-[#1C4645] px-2 py-2"
                                            style={{
                                                height: '40px',
                                                maxWidth: '220px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {countries?.getName(entry.country, "en")}
                                        </td>
                                        <td
                                            className="border-l border-t border-[#1C4645] px-2 py-2"
                                            style={{
                                                height: '40px',
                                                width: '150px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {formatValue(selectedMetric, entry?.value, selectedCurrency, convertAmount)}
                                        </td>
                                    </tr>
                                ))}

                                {Array.from({ length: Math.max(10 - currentMetricData?.length, 0) })?.map((_, index) => (
                                    <tr key={`empty-row-${index}`} style={{ textAlign: 'center' }}>
                                        <td
                                            className="border-t border-[#1C4645] px-2 py-2"
                                            style={{
                                                height: '40px',
                                                maxWidth: '220px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {/* Empty */}
                                        </td>
                                        <td
                                            className="border-l border-t border-[#1C4645] px-2 py-2"
                                            style={{
                                                height: '40px',
                                                width: '150px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {/* Empty */}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default Histogram;