import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const PopUpAlert = ({ data, value, handleAction,index }) => {

    confirmAlert({
        customUI: ({ onClose }) => {
            const renderContent = () => {
                // Determine content based on your cases
                switch (value) {
                    case 'config':
                        return (
                            <div
                                className="bg-white"
                                style={{
                                    background: "#eae4e4",
                                    padding: "34px",
                                    borderRadius: "20px",
                                }}
                            >
                                <h2>Are you sure?</h2>
                                <p>You want to delete this config?</p>

                                <button
                                    style={{ marginRight: "10px" }}
                                    className={"button alert"}
                                    onClick={() => {
                                        handleAction(data)
                                        onClose();
                                    }}
                                >
                                    Yes, Delete it!
                                </button>
                                <button className={"button alert"} onClick={onClose}>
                                    No
                                </button>
                            </div>
                        );
                    case 'user':
                        return (
                            <div
                                className="bg-white"
                                style={{
                                    background: "#eae4e4",
                                    padding: "34px",
                                    borderRadius: "20px",
                                }}
                            >
                                <h2>Are you sure?</h2>
                                <p>You want to delete this user?</p>

                                <button
                                    style={{ marginRight: "10px" }}
                                    className={"button alert"}
                                    onClick={() => {
                                        handleAction(data)
                                        onClose();
                                    }}
                                >
                                    Yes, Delete it!
                                </button>
                                <button className={"button alert"} onClick={onClose}>
                                    No
                                </button>
                            </div>
                        );
                    case 'pause':
                        return (
                            <div
                                className="bg-white"
                                style={{
                                    background: "#eae4e4",
                                    padding: "34px",
                                    borderRadius: "20px",
                                }}
                            >
                                <h2>Are you sure?</h2>
                                <p>You want to deactivate this config ?</p>

                                <button
                                    style={{ marginRight: "10px" }}
                                    className={"button alert"}
                                    onClick={() => {
                                        handleAction(index,data)
                                        onClose();
                                    }}
                                >
                                    Yes, Deactivate it!
                                </button>
                                <button className={"button alert"} onClick={onClose}>
                                    No
                                </button>
                            </div>
                        );
                    case 'play':
                        return (
                            <div
                                className="bg-white"
                                style={{
                                    background: "#eae4e4",
                                    padding: "34px",
                                    borderRadius: "20px",
                                }}
                            >
                                <h2>Are you sure?</h2>
                                <p>You want to activate this config?</p>

                                <button
                                    style={{ marginRight: "10px" }}
                                    className={"button alert"}
                                    onClick={() => {
                                        handleAction(index,data)
                                        onClose();
                                    }}
                                >
                                    Yes, Activate it!
                                </button>
                                <button className={"button alert"} onClick={onClose}>
                                    No
                                </button>
                            </div>
                        );
                    default:
                        return (
                            <div className="bg-white" style={{ background: "#eae4e4", padding: "34px", borderRadius: "20px" }}>
                                <h2>Are you sure?</h2>
                                <p>You want to delete this?</p>
                                <button style={{ marginRight: "10px" }} className={"button alert"} onClick={() => handleAction(data,true)}>
                                    Yes, Delete it!
                                </button>
                                <button className={"button alert"} onClick={() => onClose()}>
                                    No
                                </button>
                            </div>
                        );
                }
            };

            return renderContent();
        },
    });

};

export default PopUpAlert;
