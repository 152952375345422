import { useConfigureContext } from "../Context/ConfigureContext"

const ShapeSelector = () => {
    const {
        alertLevel,
        setAlertLevel
    } = useConfigureContext();

    const handleShapeChange = (event) => {
        setAlertLevel(event.target.value);
    };

    return (
        <div style={{}} className=" px-5 shadow bg-white m-0 flex align-center justify-space additional-buttons">
            <h3 className='thirdh3'>Select Alert level</h3>
            <div className="shape-selector">
                <label className="shape-label">
                    <input
                        type="radio"
                        value="GENERAL"
                        checked={alertLevel === 'GENERAL'}
                        onChange={handleShapeChange}
                    />
                    <svg width="30" height="30">
                        <circle
                            cx="15"
                            cy="15"
                            r="10"
                            fill={alertLevel === 'GENERAL' ? 'green' : 'none'}
                            stroke="black"
                        />
                    </svg>
                    General
                </label>
                <label className="shape-label">
                    <input
                        type="radio"
                        value="LOW"
                        checked={alertLevel === 'LOW'}
                        onChange={handleShapeChange}
                    />
                    <svg width="30" height="30">
                        <polygon
                            points="15,5 25,25 5,25"
                            fill={alertLevel === 'LOW' ? 'orange' : 'none'}
                            stroke="black"
                        />
                    </svg>
                    Low
                </label>
                <label className="shape-label">
                    <input
                        type="radio"
                        value="MODERATE"
                        checked={alertLevel === 'MODERATE'}
                        onChange={handleShapeChange}
                    />
                    <svg width="30" height="30">
                        <rect
                            x="7.5"
                            y="7.5"
                            width="15"
                            height="15"
                            fill={alertLevel === 'MODERATE' ? 'yellow' : 'none'}
                            stroke="black"
                        />
                    </svg>
                    Moderate
                </label>
                <label className="shape-label">
                    <input
                        type="radio"
                        value="HIGH"
                        checked={alertLevel === 'HIGH'}
                        onChange={handleShapeChange}
                    />
                    <svg width="30" height="30" viewBox="0 0 30 30">
                        <polygon
                            points="15,4 18,11 25,11 19,15 21,22 15,18 9,22 11,15 5,11 12,11"
                            fill={alertLevel === 'HIGH' ? 'red' : 'none'}
                            stroke="black"
                        />
                    </svg>
                    High
                </label>

            </div>
        </div>
    );
};

export default ShapeSelector;
