import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { listOfMterix } from "../utils/constants";
import { calculatePercentageChange } from "../utils/HelperFunction";
import { formatCurrency } from "../utils/currencyFormatter";
import UseCurrencyConverter from "../Hooks/useCurrencyConverter";

const CustomTooltip = ({ active, payload, metricName, selectedCurrency, convertAmount }) => {
  if (active && payload && payload.length) {
    const originalDate = payload[0]?.payload?.originalDate || "N/A";
    const comparisonDate = payload[0]?.payload?.comparisonDate || "N/A";
    const originalValue = payload[0]?.value || 0;
    const comparisonValue = payload[1]?.value || 0;

    return (
      <div className="bg-white p-2 border rounded shadow-md">
        <h3 className="text-black font-medium">{metricName}</h3>
        <p className="text-[#8884d8]">{`Original Date: ${originalDate}`}</p>
        <p className="text-[#8884d8]">
          Original Value: {metricName === "CPC" || metricName === "CPM" || metricName === "CPA" || metricName === "Cost" || metricName === "CPCV" || metricName === "VCPM"
            ? `${selectedCurrency === "USD" ? '$' : selectedCurrency === "EUR" ? '€' : selectedCurrency === "GBP" ? '£' : '₹'} ${new Intl.NumberFormat().format(convertAmount(originalValue, selectedCurrency))}`
            : metricName === "CTR" || metricName === "Conv. Rate"
              ? `${formatCurrency(originalValue)}%`
              : `${formatCurrency(originalValue)}`}
        </p>
        <p className="text-[#82ca9d]">{`Comparison Date: ${comparisonDate}`}</p>
        <p className="text-[#82ca9d]">
          Comparison Value: {metricName === "CPC" || metricName === "CPM" || metricName === "CPA" || metricName === "Cost" || metricName === "CPCV" || metricName === "VCPM"
            ? `${selectedCurrency === "USD" ? '$' : selectedCurrency === "EUR" ? '€' : selectedCurrency === "GBP" ? '£' : '₹'} ${new Intl.NumberFormat().format(convertAmount(comparisonValue, selectedCurrency))}`
            : metricName === "CTR" || metricName === "Conv. Rate"
              ? `${formatCurrency(comparisonValue)}%`
              : `${formatCurrency(comparisonValue)}`}
        </p>
      </div>
    );
  }
  return null;
};

const DashboardChart = ({ dashBoardData, selectedMetrics, loading, apiAmount, selectedCurrency }) => {
  const { convertAmount } = UseCurrencyConverter(apiAmount);

  if (!dashBoardData) {
    return <div className="text-red-600 flex justify-center text-base font-medium w-full h-full items-center">Loading advertiser data...</div>;
  }
  else if (!dashBoardData?.selectedDateData?.responseDateWise) {
    return <div className="text-red-600 flex justify-center text-base font-medium w-full h-full items-center">No data available</div>;
  }

  const charts = Array.from(selectedMetrics)?.map((metric) => {
    const chartData = dashBoardData?.selectedDateData?.responseDateWise?.map((originalItem, index) => {
      const comparisonItem = dashBoardData?.comparisionDateData?.responseDateWise[index] || {};
      return {
        originalDate: originalItem?.date,
        comparisonDate: comparisonItem?.date,
        originalMetric: originalItem[metric],
        comparisonMetric: comparisonItem[metric] || 0,
      };
    });
    const calculatedValue = calculatePercentageChange(dashBoardData?.selectedDateData?.[metric], dashBoardData?.comparisionDateData?.[metric])
    const metricChart = listOfMterix?.find((obj) => {
      return obj?.key === metric
    })?.metric;

    return (
      <div className="border-[2px] rounded-[10px] shadow-lg p-2.5 max-2xl:ml-5 bg-[#E6F9FF]">
        <div key={metric} className=" bg-white max-h-[500px] max-xl:max-w-[450px] xl:min-w-[500px] 2xl:min-w-[685px] text-[#00292C] py-3 px-5 text-center rounded-xl">
          <h3>{metricChart}</h3>
          <div className="flex pb-5 justify-around">
            <div className="text-3xl font-medium text-[#1c4546]">
              {metric === "cpc" ||
                metric === "cpm" ||
                metric === "cpa" ||
                metric === "revenue" ||
                metric === "cpcv" ||
                metric === "vcpm"
                ? `${selectedCurrency === "USD"
                  ? "$"
                  : selectedCurrency === "EUR"
                    ? "€"
                    : selectedCurrency === "GBP"
                      ? "£"
                      : "₹"
                } 
                ${new Intl.NumberFormat().format(
                  convertAmount(dashBoardData?.selectedDateData?.[metric], selectedCurrency)
                )}`
                : metric === "ctr" || metric === "conversion_rate"
                  ? `${formatCurrency(dashBoardData?.selectedDateData?.[metric])}%`
                  : formatCurrency(dashBoardData?.selectedDateData?.[metric])}
            </div>
            <div
              className={`text-lg font-medium flex items-center ${calculatedValue < 0 ? 'text-red-400' : 'text-green-400'}`}
            >
              <img src={`${calculatedValue < 0 ? '/red_arrow.png' : '/green_arrow.png'}`} alt="arrow-down" className="h-5" />{Math.abs(calculatedValue)}%
            </div>
          </div>
          <ResponsiveContainer height={300}>
            <LineChart data={chartData} margin={{ top: 5, right: 5, left: 25, bottom: 5 }}>
              <XAxis dataKey="originalDate" tick={{ fill: "#1c4546", fontWeight: "600" }} />
              <YAxis
                tick={{ fill: "#1c4546", fontWeight: "600" }}
                tickFormatter={(value) => {
                  const requiresSpecialFormatting = ["cpc", "cpm", "cpa", "revenue", "cpcv", "vcpm"].includes(metric);
                  const requiresPercentage = ["ctr", "conversion_rate"].includes(metric);

                  if (requiresSpecialFormatting) {
                    // Formatting for INR
                    if (selectedCurrency === "INR") {
                      if (value >= 1000000) {
                        return `₹${(value / 1000000).toFixed(2)}M`;
                      } else if (value >= 1000) {
                        return `₹${(value / 1000).toFixed(2)}K`;
                      } else {
                        return `₹${value.toFixed(2)}`;
                      }
                    } else { // Formatting for USD, GBP, EUR
                      let convertedValue = convertAmount(value, selectedCurrency);
                      convertedValue = Number(convertedValue) || 0;
                      const currencySymbol = selectedCurrency === "USD" ? "$" : selectedCurrency === "EUR" ? "€" : "£";
                      if (convertedValue >= 1000000) {
                        return `${currencySymbol}${(convertedValue / 1000000).toFixed(1)}M`;
                      } else if (convertedValue >= 1000) {
                        return `${currencySymbol}${(convertedValue / 1000).toFixed(1)}K`;
                      } else {
                        return `${currencySymbol}${convertedValue.toFixed(2)}`;
                      }
                    }
                  } else if (requiresPercentage) {
                    return `${(value)}%`
                  } else {
                    if (value >= 1000000) {
                      return `${(value / 1000000).toFixed(0)}M`;
                    } else if (value >= 1000) {
                      return `${(value / 1000).toFixed(0)}K`;
                    } else {
                      return `${value}`;
                    }
                  }

                  // return `${value}`; // Default case
                }}
              />

              <Tooltip content={<CustomTooltip metricName={metricChart} selectedCurrency={selectedCurrency} convertAmount={convertAmount} />} />
              <Legend wrapperStyle={{
                paddingTop: "10px",
                justifyContent: "center",
                display: "flex",
              }} />
              <Line type="linear" dataKey="originalMetric" stroke="#8884d8" strokeWidth={3} name="Selected date" dot={false} />
              <Line type="linear" strokeDasharray="5 5" dataKey="comparisonMetric" stroke="#82ca9d" strokeWidth={3} name="Comparison date" dot={false} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  });

  return <div className="flex flex-col">{charts}</div>;
};

export default DashboardChart;


// else { // Formatting for USD, GBP, EUR
//   const convertedValue = convertAmount(value, selectedCurrency);
//   if (convertedValue >= 1000000) {
//     return `${(convertedValue / 1000000).toFixed(1)}M`; // For amounts 1,000,000 and above
//   } else if (convertedValue >= 1000) {
//     return `${(convertedValue / 1000).toFixed(1)}K`; // For amounts 1,000 and above
//   } else {
//     return `${convertedValue}`; // For amounts less than 1,000
//   }
// }