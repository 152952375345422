import React, { useRef, useState } from "react";
import { RunMonitoringFor } from "../utils/constants";
import moment from "moment";
import DateRangePickerValue from "../Date Range Picker/DateRangePicker"
import { useConfigureContext } from "../Context/ConfigureContext"
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";


const TitleTab = (props) => {
  const [isTitleDDActive, setisTitleDDActive] = useState(false);

  const {
    setEndDate,
    setStartDate,
    selectedRange,
    title,
    setTitle,
    dateSelected,
    setDateSelected,
    pickDate,
    setPickDate,
    startDate,
    endDate,
  } = useConfigureContext();
  const ref = useRef();

  const isCustomDatesSelected = dateSelected === "CUSTOM_DATES";
  const showDatePicker = isCustomDatesSelected && selectedRange && selectedRange.length > 0;
  const datePickerWidth = showDatePicker ? '100%' : '50%';
  // const frequentlyInputWidth = '100%';

  // const freqWidth = showDatePicker ? '30%' : '35%';
  const dropdownWidth = showDatePicker ? '65%' : '60%';
  // const selectWidth = showDatePicker ? '50%' : '100%';
  const inputWidth = showDatePicker ? '40%' : '100%';


  const onChangeTitle = (e) => {
    setTitle(e.target.value);
  };


  const handleRangeChange = (ranges) => {
    setStartDate(moment(ranges.startDate).format('YYYY-MM-DD'))
    setEndDate(moment(ranges.endDate).format('YYYY-MM-DD'));
  };

  return (
    <>
      <div className="sumry mb-2">

        <div className={`freq2 flex align-start gap-10 justify-space ${dateSelected === "CUSTOM_DATES" ? "custom-dates" : ""}`}>
          <div className="flex  justify-between w-full">
            <div className="bg-white w-[25dvw] pl-6 py-7 max-h-28 shadow">
              <TextField required variant="outlined" label="Title" className="freq-input w-[90%] bg-white"
                style={{}}
                InputProps={{
                  style: {
                    height: '55px',
                  },
                }}
                value={title}
                placeholder="Type your title"
                onChange={(e) => onChangeTitle(e)}
              >
              </TextField>
            </div>

            <div className="drpdwn bg-white shadow" style={{ width: dropdownWidth }}>
              <div className="drpdwn1 flex items-center justify-between gap-5 mt-4 my-auto" >

                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="date-select-label">Frequency</InputLabel>
                  <Select
                    label="Frequency"
                    labelId="date-select-label"
                    value={dateSelected}
                    onChange={(e) => {
                      if (e.target.value === 'CUSTOM_DATES') {
                        setPickDate(true);
                      }
                      setDateSelected(e.target.value);
                      setisTitleDDActive(!isTitleDDActive);
                    }}
                    style={{}}
                  >
                    {RunMonitoringFor.map((option, index) => (
                      <MenuItem key={index} value={option.key} className="item">
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {showDatePicker && (
                  <TextField

                    variant="outlined"
                    style={{ width: inputWidth }}
                    value={`${startDate} - ${endDate}`}
                    placeholder={"Select date"}
                    onClick={() => {
                      setPickDate((oldState) => !oldState);
                    }}
                    InputProps={{
                      style: {
                        height: '55px',
                      },
                    }}
                  />
                )}
              </div>

              {showDatePicker && (
                <div className="date-picker" ref={ref} style={{ width: datePickerWidth }}>
                  <>
                    {pickDate && (
                      <DateRangePickerValue
                        handleRangeChange={handleRangeChange}
                        pickDate={pickDate}
                        setPickDate={setPickDate}
                        defaultDate={{
                          "startDate": startDate,
                          "endDate": endDate,
                        }}
                      />
                    )}
                  </>
                </div>
              )}
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default TitleTab;