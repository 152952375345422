/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { useState } from "react";
import Header from "../template/Header";
import advertise from "../../assets/icons/advertisers.svg";
import Footer from "../template/Footer";
import CategoryController from "../category/category-controller";
import UserController from "./user-controller";
import UserData from "./user-data";
import PopUp from "./pop-up";
import Form from "./form";
import { useDataContext } from "../Context/context";
import useToast from "../Hooks/Toast"

const NewUserManagement = () => {
  const notify = useToast();
  const [popup, setPopup] = useState(true);
  const [selected, setSelected] = useState({
    key: "ADVERTISERS",
    label: "Advertisers",
  });
  const { saveUserData, loading } = UserController();
  const [selectedRole, setSelectedRole] = useState({
    key: "Select Role",
    label: "Select Role",
  });
  const { entityTypeData } = CategoryController(selected?.key);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [showFilterGroup, setShowFilterGroup] = useState(false);

  const [saveEnabled, setSaveEnabled] = useState(false);
  const [savedFilterOption, setSavedFilterOption] = useState("");
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const { editData, setEditData } = useDataContext();

  const saveUser = () => {
    let advertiserIds;
    let partnerIds;
    if (selectedData?.length) {
      if (selectedData[0][0].savedFilterOption === "Advertisers") {
        advertiserIds = selectedData[0][0]?.selectedOptions.map((e) => e.id);
      } else if (selectedData[0][0].savedFilterOption === "Partners") {
        partnerIds = selectedData[0][0]?.selectedOptions.map((e) => e.id);
      }
    }
    if (name?.length && email?.length) {
      const data = {
        id: editData?.length ? editData[0].id : "",
        role: selectedRole?.key,
        agencyId: "e604ddbf-8307-4ae1-b3e9-e20ec1396e9c", //change when we have multiple agency id
        advertiserIds: advertiserIds
          ? advertiserIds
          : editData?.length !== 0
            ? editData[0]?.advertiserIds
            : [],
        partnerIds: partnerIds
          ? partnerIds
          : editData?.length !== 0
            ? editData[0]?.partnerIds
            : [],
        email: email,
        name: name,
        is_active: true
      };

      saveUserData(data);
    } else {
      notify(`Add Email and name`, 'error');
    }
  };

  const saveData = () => {
    setPopup(true);
    setSaveEnabled(true);
    setSavedFilterOption((prev) => [...prev, selected]);
    setSelectedData((prev) => [
      ...prev,
      [{ savedFilterOption: selected.label, selectedOptions }],
    ]);
    // setSelectedOptions([]);
  };

  return (
    <>
      <Header />
      {loading && editData ? (
        <div className="loading flex align-center justify-center">
          <div className="loading-Overlay"> </div>
        </div>
      ) : (
        <div className="bg-color">
          <div className="body">
            <div className="container-small">
              <div className="bg-white mb-35 newusers">
                <Form
                  email={email}
                  setEmail={setEmail}
                  name={name}
                  setName={setName}
                  selected={selected}
                  setSelected={setSelected}
                  selectedRole={selectedRole}
                  setSelectedRole={setSelectedRole}
                  editData={editData}
                />
              </div>

              <div className="add-patners bg-white">
                <div className="form-field1">
                  <label>Access</label>
                </div>

                <div className="bg-white mb-35">
                  <div className="flex justify-space">
                    <button className="font-normal px-5 py-2 mx-5 mb-3 rounded-[7px] text-white bg-primaryColor border border-primaryColor hover:text-primaryColor hover:bg-white" onClick={() => setPopup(!popup)}>
                      +&nbsp;&nbsp;ADD PARTNERS OR ADVERTISERS
                    </button>
                  </div>
                  {!saveEnabled &&
                    !editData[0]?.advertiserIds.length &&
                    !editData[0]?.partnerIds.length ? (
                    <div className="advertisers flex align-center flex-direction">
                      <img src={advertise} />
                      <p>
                        You can give users different roles for each advertisers
                        or partner they have access to
                      </p>
                      <a href="">ADD PARTNERS OR ADVERTISERS</a>
                    </div>
                  ) : (
                    <>
                      <UserData
                        selectedData={selectedData}
                        editData={editData}
                        entityTypeData={entityTypeData}
                        setSelected={setSelected}
                        saveEnabled={setSaveEnabled}
                        setEditData={setEditData}
                        setSelectedData={setSelectedData}
                        setSelectedOptions={setSelectedOptions}
                      />
                    </>
                  )}
                  {!popup && (
                    <PopUp
                      setPopup={setPopup}
                      popup={popup}
                      selectedOptions={selectedOptions}
                      entityTypeData={entityTypeData}
                      setShowFilterGroup={setShowFilterGroup}
                      setSelectedOptions={setSelectedOptions}
                      saveData={saveData}
                    />
                  )}
                </div>

                <div className="btn">
                  {saveEnabled ? (
                    <button
                      className="font-normal m-4 px-5 py-2 rounded-[7px] text-white bg-primaryColor border border-primaryColor hover:text-primaryColor hover:bg-white"
                      onClick={() => saveUser()}
                    >
                      {editData?.length ? "Update User" : "Create User"}
                    </button>
                  ) : (
                    ""
                  )}
                </div>
                {/* <div className="btn">
                  {saveEnabled ? (
                    <button className="font-normal m-4 px-5 py-2 rounded-[7px] text-white bg-primaryColor border border-primaryColor hover:text-primaryColor hover:bg-white" onClick={() => saveUser()}>
                      Create User
                    </button>
                  ) : (
                    ""
                  )}
                </div> */}

              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};
export default NewUserManagement;
