import React from "react";
import { useConfigureContext } from "../Context/ConfigureContext"
import { RadioGroup, Radio, FormControlLabel } from '@mui/material';
const FilterGroup = (props) => {
  const {
    handleAddFilterGroupClick,
    showFilterGroup,
    preFilledData,
    showFilterTypeDropdown,
    setShowFilterTypeDropdown,
    operator,
    setOperator,
    filterGroups,
  } = useConfigureContext();

  const toggleFilterTypeDropdown = () => {
    setShowFilterTypeDropdown(true);
  };

  const handleOperatorChange = (event) => {
    setOperator(event.target.value);
  };

  return (
    <>
      {showFilterGroup || preFilledData.length ? (
        <div className="summary3 triggers bg-white shadow mt-100 flex align-center justify-space">
          <h3 className="thirdh3">Add Triggers / Filters</h3>

          <div className="Tabs">
            <RadioGroup
              row
              aria-label="operator"
              name="operator"
              value={operator}
              onChange={handleOperatorChange}
            >
              <FormControlLabel
                value="or"
                control={<Radio sx={{ color: operator === "or" ? '#30a953' : 'initial' }} />}
                label="OR"
                className={operator === "or" ? "or" : ""}
              />
              <FormControlLabel
                value="and"
                control={<Radio sx={{ color: operator === "and" ? '#30a953' : 'initial' }} />}
                label="AND"
                className={operator === "and" ? "and" : ""}
              />
            </RadioGroup>
          </div>
          {showFilterGroup || preFilledData.length ? (
            <div className="filter-group-dropdown">
              <div className="filter-group-dropdown-list">
                <button className="font-normal px-5 py-2 rounded-[7px] text-white bg-primaryColor border border-primaryColor hover:text-primaryColor hover:bg-white user" onClick={toggleFilterTypeDropdown}>
                  Add Filter Group
                </button>
                {showFilterTypeDropdown && (
                  <div className="filter-type-dropdown">
                    <button
                      className="hover:text-primaryColor"
                      onClick={() => handleAddFilterGroupClick("dimension", `dimension-${filterGroups.length + 1}`)}
                    >
                      Dimension Filter
                    </button>
                    <button
                      className="filter-type-button"
                      onClick={() => handleAddFilterGroupClick("metric", `metric-${filterGroups.length + 1}`)}
                    >
                      Metric Filter
                    </button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <button className="button">Add Filter Group</button>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default FilterGroup;
