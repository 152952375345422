import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { cookieSetter } from '../utils/HelperFunction';
import {useDataContext} from "../Context/context"
import useToast from "../Hooks/Toast"
import { getEmailFromToken } from '../utils/getUserDetail';

const LoginController = () => {
  const { toSetLogin } = useDataContext();
  const notify = useToast();
  const [googleLoginInfo, setGoogleLoginInfo] = useState({
    inProgress: false,
    error: "",
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const responseGoogle = async ({ credential, profileObj }) => {
    try {
      setLoading(true);
      // Set as logging in
      const baseUrl = `https://monitoring-webapp-iukyyubnkq-uc.a.run.app`;
      setGoogleLoginInfo({ ...googleLoginInfo, inProgress: true });

      const response = await fetch(`${baseUrl}/users/google-login`, {
        method: "POST",
        headers: { Authorization: `Bearer ${credential}` },
      });
      if (response.ok) {
        notify(`Welcome ! Youre logedIn`, 'success');
        const { jwtToken } = await response.json();
        onAppSignIn(jwtToken);
      } else {
        notify(`There is some issue please try again`, 'error');
        const { data } = await response.json();
        setGoogleLoginInfo({
          ...googleLoginInfo,
          error: data?.detail || "Something went wrong... Please try again!",
        });
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
      setGoogleLoginInfo({ ...googleLoginInfo, inProgress: false });
    }
  };

  const onAppSignIn = async (idToken) => {
    localStorage.clear();
    // Cookies.set("appIdToken", idToken);
    // Cookies.set("ExpiryTime",moment())
    cookieSetter("appIdToken", idToken)
    getEmailFromToken(idToken);
    setLoading(false);
    toSetLogin();
    navigate("/home", { replace: true });
    notify();
  };
  return {
    loading,
    responseGoogle, 
  };
};

export default LoginController;
