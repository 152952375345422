import { FormControl, InputLabel, MenuItem, Select, InputAdornment, OutlinedInput } from "@mui/material";
import React, { useEffect, useState } from "react";
import CountryDetails from "../Hooks/Dashboard/useCountry";
import i18nIsoCountries from "i18n-iso-countries";
import world from "../../assets/icons/world.png";

i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const CountryList = ({ advertiserId, selectedCountry, setSelectedCountry }) => {
    const { country = [], getCountry, loading } = CountryDetails();
    const [countryMap, setCountryMap] = useState(new Map());

    useEffect(() => {
        if (advertiserId) {
            getCountry(advertiserId);
            setSelectedCountry("");
        }
    }, [advertiserId]);

    useEffect(() => {
        if (country.length > 0) {
            const newCountryMap = new Map(
                country?.map(code => [code, i18nIsoCountries.getName(code, "en")])
            );
            setCountryMap(newCountryMap);
            // setSelectedCountry(country[0]);
        }
    }, [country]);

    const handleCountry = (e) => {
        const selectedCode = e.target.value;
        setSelectedCountry(selectedCode);
    };

    return (
        <div className="lg:min-w-40 max-h-5">
            <FormControl fullWidth variant="outlined">
                <Select
                    id="country-select"
                    value={selectedCountry || ""}
                    onChange={handleCountry}
                    displayEmpty
                    disabled={loading || !advertiserId}
                    input={
                        <OutlinedInput
                            startAdornment={
                                <InputAdornment position="start">
                                    <img
                                        src={world}
                                        alt="world icon"
                                        className="mr-3 pb-0.5"
                                    />
                                </InputAdornment>
                            }
                            sx={{
                                height: "55px",
                                width: "220px",
                                backgroundColor: "white",
                                color: "#1c4546",
                                borderRadius: "10px",
                                "& .MuiOutlinedInput-notchedOutline": { borderColor: "#1c4546" },
                                "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#1c4546" },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "#1c4546" },
                                "& .MuiSvgIcon-root": { color: "#1c4546" }
                            }}
                        />
                    }
                >
                    <MenuItem value="">
                        Select Country
                    </MenuItem>
                    {loading || !advertiserId ? (
                        <MenuItem value="" disabled>
                            Loading...
                        </MenuItem>
                    ) : (
                        Array.from(countryMap)?.map(([code, name]) => (
                            <MenuItem key={code} value={code}>
                                {name}
                            </MenuItem>
                        ))
                    )}
                </Select>
            </FormControl>
        </div>
    );
};

export default CountryList;