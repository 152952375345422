import { useNavigate } from "react-router-dom";
import { cookieGetter } from '../utils/HelperFunction'
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useDataContext } from "../Context/context";
import { toast } from "react-toastify";

const UserController = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const { setEditData } = useDataContext();
  const appIdToken = cookieGetter("appIdToken");
  const [userData, setUserData] = useState();
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState(null);
  const notify = () => toast(`You don't have permission to access this page.`);
  const getUserUsingAPI = useCallback(
    async (selected) => {
      try {
        const baseUrl = "https://monitoring-webapp-iukyyubnkq-uc.a.run.app";
        const response = await axios.get(
          `https://monitoring-webapp-iukyyubnkq-uc.a.run.app/users`,
          {
            baseUrl,
            method: "GET",
            headers: { Authorization: `Bearer ${appIdToken}` },
          }
        );
        setLoading(false);
        setUserData(response?.data);
        return response?.data;
      } catch (error) {
        setLoading(false);
        console.error("Error fetching entities:", error);
        // throw error;
      }
    },
    [appIdToken]
  );

  const fetchUserProfile = async () => {
    try {
      const response = await axios.get(
        `https://monitoring-webapp-iukyyubnkq-uc.a.run.app/users/profile`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${appIdToken}` },
        }
      );

      setProfile(response?.data?.users)
    } catch (err) {
      console.error("error in fetching profile", err)
    }
  }

  const baseURL = "https://monitoring-webapp-iukyyubnkq-uc.a.run.app/users";
  const saveUserData = async (finalObject) => {
    fetch(
      finalObject.id
        ? `https://monitoring-webapp-iukyyubnkq-uc.a.run.app/users/${finalObject.id}`
        : baseURL,
      {
        method: finalObject.id ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${appIdToken}`,
        },
        body: JSON.stringify(finalObject),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        // Handle the response data here
        const notify = () => toast(`User Created Successfully `);
        notify();
        navigate("/user");
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch.
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    getUserUsingAPI();
    fetchUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (e) => {
    const inputValue = e.target.value;
    setSearchInput(inputValue);

    const filtered = userData.users.filter((item) =>
      item.name.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleCopy = (editData) => {
    const data = userData?.users.filter(
      (userData) => userData?.id === editData
    );
    setEditData(data);
    setLoading(false);
    navigate("/new-user");
  };

  const handleDelete = async (deleteData) => {
    try {
      const response = await axios.patch(
        `https://monitoring-webapp-iukyyubnkq-uc.a.run.app/users/${deleteData.id}:deactivate`,
        null,
        {
          headers: { Authorization: `Bearer ${appIdToken}` },
        }
      );

      if (response.status === 204) {
        setUserData((prevData) =>
          prevData.filter((data) => data.id !== deleteData.id)
        );

        const notify = () => toast(`The User is Deactivated`);
        notify();
      }

      window.location.reload();
    } catch (error) {
      console.error("Error deleting monitoring config:", error);
    }
  };

  return {
    getUserUsingAPI,
    saveUserData,
    userData,
    filteredData,
    handleSearch,
    searchInput,
    setSearchInput,
    handleDelete,
    handleCopy,
    loading,
    profile
  };
};

export default UserController;
