import { useState, useEffect, useRef } from 'react';

const useToggleVisibility = () => {
    const [isVisible, setIsVisible] = useState(null);
    const elementRef = useRef(null);
    const buttonRef = useRef([]);

    const handleClickOutside = (event) => {
        if (
            elementRef.current && !elementRef.current.contains(event.target) &&
            buttonRef.current.every(ref => ref && !ref.contains(event.target))
        ) {
            setIsVisible(null);
        }
    };

    const handleButtonClick = (index) => {
        if (index === isVisible) {
            setIsVisible(null);
        } else {
            setIsVisible(index);
        }
    };


    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return {
        isVisible,
        handleButtonClick,
        elementRef,
        buttonRef
    };
};

export default useToggleVisibility;
