import React, { useState, useEffect } from "react";
import Datepicker from "./datepicker";
import Header from "../template/Header";
import AdvertiserList from "./advertiserList";
import PerformanceOverview from "./performanceOverview";
import UseDashboardData from "../Hooks/Dashboard/useDashboardData";
import DashboardChart from "./dashboardChart";
import { CircularProgress } from "@mui/material";
import useToast from "../Hooks/Toast";
import UseCurrencyConverter from "../Hooks/useCurrencyConverter";
import CurrencyPicker from "./currencyPicker";
import Histogram from "./histogram";
import PieChartDashboard from "./pieChart";
import DeviceList from "./deviceList";
import CountryList from "./countryList";
import CampaignTable from "./campaignTable";

const Dashboard = () => {
  const [advertiserId, setAdvertiserId] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedDateRange, setSelectedDateRange] = useState({});
  const [temporaryMetrics, setTemporaryMetrics] = useState(new Set()); // Temporary state for metrics
  const [submittedMetrics, setSubmittedMetrics] = useState(new Set()); // State for metrics after submission
  const { selectedCurrency, setSelectedCurrency } = UseCurrencyConverter();
  const [selectedDevice, setSelectedDevice] = useState("");

  const { dashboardApiCall, loading, setLoading, dashBoardData } = UseDashboardData();
  const notify = useToast();

  const [advertiserListLoaded, setAdvertiserListLoaded] = useState(false); // Track if advertisers are loaded

  const handleSubmit = async () => {
    if (!advertiserId) {
      notify("Select an advertiser", "error");
      return;
    }
    if (!selectedDateRange?.startDate && !selectedDateRange?.endDate) {
      notify("Select date range first", "error");
      return;
    }
    if (temporaryMetrics?.size === 0) {
      notify("Select at least one metric", "error");
      return;
    }

    setLoading(true);
    const finalObject = {
      startDate: selectedDateRange?.startDate,
      endDate: selectedDateRange?.endDate,
      advertiserId: advertiserId,
      selectedMetrics: Array.from(temporaryMetrics),
      country: selectedCountry,
      device: selectedDevice
    };

    await dashboardApiCall(finalObject);
    setSubmittedMetrics(temporaryMetrics);
    setLoading(false);
  };

  // Call handleSubmit only after advertiserId, selectedDateRange, or temporaryMetrics change, and advertisers are loaded
  useEffect(() => {
    if (advertiserListLoaded) {
      handleSubmit();
    }
  }, [advertiserId, selectedDateRange, temporaryMetrics, advertiserListLoaded, selectedDevice, selectedCountry]);

  return (
    <>
      <Header />
      <div className="px-5 lg:px-12">
        <div className="flex max-lg:flex-col">
          <div className="flex max-md:flex-col w-full max-md:justify-center max-md:items-center items-center md:justify-between gap-3">
            <div className="flex max-md:flex-col max-md:justify-center max-md:items-center gap-2 2xl:gap-5 relative h-[75px] p-2 top-24 border-[2px] rounded-[10px] shadow-lg">
              <AdvertiserList
                advertiserId={advertiserId}
                setAdvertiserId={(id) => {
                  setAdvertiserId(id);
                  setAdvertiserListLoaded(true); // Set advertiserListLoaded to true when advertiser is selected
                }}
              />
              <CurrencyPicker
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={setSelectedCurrency}
              />
              <DeviceList
                selectedDevice={selectedDevice}
                setSelectedDevice={setSelectedDevice}
                advertiserId={advertiserId}
              />
              <CountryList
                advertiserId={advertiserId}
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
              />
            </div>
            <div>
              <Datepicker
                selectedDateRange={selectedDateRange}
                setselectedDateRange={setSelectedDateRange}
              />
            </div>
          </div>
        </div>
        <div className="mt-32">
          <div className="font-medium text-xl pb-4 2xl:pl-8">Select the chart you want to see</div>
          <div className="flex items-center justify-around">
            <PerformanceOverview
              temporaryMetrics={temporaryMetrics}
              setTemporaryMetrics={setTemporaryMetrics}
              selectedDateRange={selectedDateRange}
              advertiserId={advertiserId}
              selectedCurrency={selectedCurrency}
              selectedDevice={selectedDevice}
              selectedCountry={selectedCountry}
            />
            {loading ? (
              <div className="w-[50%] flex flex-col gap-3 justify-center items-center">
                <CircularProgress
                  size={40}
                  color="inherit"
                  sx={{ color: "#1c4546" }}
                />
                <div>Loading...</div>
              </div>
            ) : (
              <div className="min-w-[50%] flex justify-center items-center">
                <DashboardChart
                  dashBoardData={dashBoardData}
                  selectedMetrics={submittedMetrics}
                  loading={loading}
                  selectedCurrency={selectedCurrency}
                />
              </div>
            )}
          </div>
        </div>
        <div className="w-full mt-16">
          <PieChartDashboard
            selectedDateRange={selectedDateRange}
            advertiserId={advertiserId}
            selectedCurrency={selectedCurrency} />
        </div>
        <div className="mt-14 max-2xl:mt-12 2xl:px-12">
          <Histogram
            selectedDateRange={selectedDateRange}
            advertiserId={advertiserId}
            selectedCurrency={selectedCurrency} />
        </div>
        <div className="mt-14 2xl:px-12">
          <CampaignTable
            selectedDateRange={selectedDateRange}
            advertiserId={advertiserId}
            selectedCurrency={selectedCurrency} />
        </div>
      </div>
    </>
  );
};

export default Dashboard;


// const data = {"mobile": [{
//   "name": "click",
//   "value": 3000
// },
// {
//   "name": "impression",
//   "value": 1000
// }
// ],
// "desktop": [{
//   "name": "click",
//   "value": 3000
// },
// {
//   "name": "impression",
//   "value": 1000
// }
// ]
// }


// {
//   Mobile : 'Smart Phone',
//   Desktop : 'Desktop',
//   Connected TV:'Connected TV'
//   Others:'others'
//   }