import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import MonitoringConfigs from "../home/home-controller";
import useToggleVisibility from '../Hooks/useActionVisibility'
import LevelOfAlert from './LevelOfAlert'
import { FaPlay } from "react-icons/fa";
import { FaPause } from "react-icons/fa";
import PopUpAlert from "../Pop-Up/PopUp"
import ConfigDetailPopup from '../Pop-Up/ConfigDetailPopup'
import { FaEdit } from "react-icons/fa";
import { FaCopy } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";


const Config = ({ filteredData, searchInput, monitoringConfigs }) => {
    const { isVisible, handleButtonClick, elementRef, buttonRef } = useToggleVisibility();
    const {
        handleDelete,
        handleActivateDeactivate,
        handleCopy,
        handleEdit,
    } = MonitoringConfigs();

    function handlePlay(index, monitoringConfig) {
        handleActivateDeactivate(
            monitoringConfig,
            "activate",
            true
        );
    }

    function handlePause(index, monitoringConfig) {
        handleActivateDeactivate(
            monitoringConfig,
            "deactivate",
            true
        );
    }

    return (
        <div className="alert-table bg-white shadow mt-35">
            <div className="alert-box-label">
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead sx={{ backgroundColor: '#afb3af' }}>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell sx={{ width: '50px' }}></TableCell>
                                <TableCell sx={{ width: '350px', textAlign: 'center' }} >Alert Name</TableCell>
                                {/* <TableCell sx={{ width: '350px', textAlign: 'center' }}>ID</TableCell> */}
                                <TableCell sx={{ width: '200px', textAlign: 'center' }}>Level</TableCell>
                                <TableCell sx={{ width: '150px', textAlign: 'center' }}>Alert Level</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(searchInput === "" ? monitoringConfigs : filteredData)?.map((monitoringConfig, index) => (
                                <TableRow key={monitoringConfig.id}>
                                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{index + 1}</TableCell>
                                    <TableCell className={'pointer-cell'} >
                                        {monitoringConfig.isActive ?
                                            <FaPause onClick={() => PopUpAlert({ data: monitoringConfig, value: 'pause', handleAction: handlePause, index })}
                                                color='green' />
                                            : <FaPlay onClick={() => PopUpAlert({ data: monitoringConfig, value: 'play', handleAction: handlePlay, index })}
                                                color='red' />
                                        }
                                    </TableCell>
                                    <TableCell sx={{
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                        textDecoration: 'none',
                                        color: 'inherit',
                                        '&:hover': {
                                            textDecoration: 'underline',
                                        },
                                    }}
                                        onClick={() => ConfigDetailPopup(monitoringConfig)}>
                                        {monitoringConfig.title}
                                    </TableCell>
                                    {/* <TableCell sx={{ textAlign: 'center' }}>{monitoringConfig.id}</TableCell> */}
                                    <TableCell sx={{ textAlign: 'center' }}>{monitoringConfig?.entityType}</TableCell>
                                    <TableCell className='' sx={{ textAlign: 'center' }}>
                                        <div className='flex w-full justify-center'><LevelOfAlert alertLevel={monitoringConfig?.alertLevel} /></div>
                                    </TableCell>
{/* three dot button width */}
                                    <TableCell sx={{ textAlign: 'center', width:'150px' }}>
                                        <button
                                            className='action-icon'
                                            ref={el => buttonRef.current[index] = el}
                                            onClick={() => handleButtonClick(index)}>
                                            <BsThreeDotsVertical
                                                className={'pointer-cell-icon'}
                                                size={20}
                                                color='#4d4b4b'
                                                backgroundColor='none'
                                            />
                                        </button>
                                        {isVisible === index && (
 // style={{display: flex}}
                                            <div ref={elementRef} style={{ position: 'relative', backgroundColor: 'lightgray', padding: '0px', zIndex: 100, maxWidth: 'fit-content' }}>
                                                <div className='action-popup'
                                                style={{display: 'flex'}}>
                                                    <FaEdit
                                                        className={'pointer-cell-icon'}
                                                        style={{ margin: '5px' }}
                                                        size={20}
                                                        color='#4d4b4b'
                                                        onClick={() =>
                                                            handleEdit(monitoringConfig?.id)
                                                        } />
                                                    <FaCopy
                                                        className={'pointer-cell-icon'}
                                                        style={{ margin: '5px' }}
                                                        size={20}
                                                        color='#4d4b4b'
                                                        onClick={() =>
                                                            handleCopy(monitoringConfig?.id)
                                                        } />
                                                    <MdDelete
                                                        className={'pointer-cell-icon'}
                                                        style={{ margin: '5px' }}
                                                        size={20}
                                                        color='#4d4b4b'
                                                        onClick={() =>
                                                            PopUpAlert({ data: monitoringConfig, value: 'config', handleAction: handleDelete, index })
                                                        } />
                                                </div>
                                            </div>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {
                    filteredData?.length === 0 && searchInput !== "" &&
                    <>
                        <div className="container1">
                            <p className="centered-text">Sorry no config matches</p>
                        </div>
                    </>
                }
            </div>
        </div>
    );
};

export default Config;
