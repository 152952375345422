import React from "react";
import { DateRangePicker } from "materialui-daterange-picker";

const DateRangePickerValue = ({ handleRangeChange, pickDate, setPickDate, defaultDate }) => {

    const apply = () => {
        setPickDate(false);
      };


    return (
        <>
            <DateRangePicker
                open={pickDate}
                toggle={()=>{}}
                closeOnClickOutside={true}
                initialDateRange={defaultDate}
                onChange={(range) => {
                    handleRangeChange(range);
                }}
            />
            <div className="apply">
                <button className="button" onClick={()=>{apply()}}>Apply</button>
            </div>
        </>
    );
}

export default DateRangePickerValue;
