import React, { useEffect, useState } from "react";
import { Radio, RadioGroup, Box } from "@mui/material";
import { listOfMterix } from "../utils/constants";
import UsePerformanceOverview from "../Hooks/Dashboard/usePerformanceOverview";
import { calculatePercentageChange } from "../utils/HelperFunction";
import { formatValue } from "../utils/currencyFormatter";
import UseCurrencyConverter from "../Hooks/useCurrencyConverter";

const PerformanceOverview = ({
  setTemporaryMetrics,
  selectedDateRange,
  advertiserId,
  selectedCurrency,
  selectedDevice,
  selectedCountry
}) => {
  const { convertAmount } = UseCurrencyConverter();
  const [selectedMetric, setSelectedMetric] = useState(0);
  const { performanceData, performanceApiCall } = UsePerformanceOverview();
  const [prevAdvertiserId, setPrevAdvertiserId] = useState(advertiserId);
  const [prevSelectedCurrency, setPrevSelectedCurrency] =
    useState(selectedCurrency);
  const [prevSelectedDateRange, setPrevSelectedDateRange] =
    useState(selectedDateRange);
  const [prevSelectedDevice, setPrevSelectedDevice] = useState(selectedDevice);
  const [prevSelectedCountry, setPrevSelectedCountry] = useState(selectedCountry);

  const performanceObject = {
    startDate: selectedDateRange?.startDate,
    endDate: selectedDateRange?.endDate,
    advertiserId: advertiserId,
    country: selectedCountry,
    device: selectedDevice,
  };

  useEffect(() => {
    if (
      advertiserId &&
      (prevAdvertiserId !== advertiserId ||
        JSON.stringify(prevSelectedDateRange) !==
        JSON.stringify(selectedDateRange) ||
        prevSelectedDevice !== selectedDevice ||
        prevSelectedCountry !== selectedCountry)
    ) {
      performanceApiCall(performanceObject);
      setPrevSelectedCurrency(selectedCurrency);
      setPrevAdvertiserId(advertiserId);
      setPrevSelectedDateRange(selectedDateRange);
      setPrevSelectedDevice(selectedDevice);
      setPrevSelectedCountry(selectedCountry);
    }
  }, [
    advertiserId,
    selectedDateRange,
    performanceApiCall,
    performanceObject,
    prevAdvertiserId,
    prevSelectedDateRange,
    selectedDevice,
    prevSelectedDevice,
    selectedCountry,
    prevSelectedCountry
  ]);

  useEffect(() => {
    if (selectedMetric !== null) {
      setTemporaryMetrics([listOfMterix[selectedMetric]?.key]);
    }
  }, [selectedMetric, setTemporaryMetrics]);

  const getBackgroundColor = (key) => {
    switch (key) {
      case "revenue":
      case "impressions":
        return "#FFF4DE";
      case "cpc":
      case "cpm":
        return "#DCFCE7";
      case "ctr":
      case "cpa":
        return "#F4E8FF";
      case "total_conversions":
      case "clicks":
        return "#FFE2E6";
      default:
        return "gray";
    }
  };

  return (
    <Box className="border-[2px] rounded-[10px] shadow-lg" sx={{
      maxWidth: "580px",
      paddingY: "15px",
      paddingRight: "20px"
    }}>
      <RadioGroup
        value={selectedMetric}
        onChange={(e) => setSelectedMetric(e.target.value)}
        className="grid lg:grid-cols-2 gap-4 sm:grid-cols-1"
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr", // single column for small screens
            sm: "1fr", // single column for small screens
            lg: "1fr 1fr", // two columns for larger screens
          },
          gap: "10px",
        }}
      >
        {listOfMterix?.map((metricItem, index) => (
          <Box
            key={index}
            className="grid grid-cols-5 my-2 items-center gap-2"
            sx={{
              fontSize: {
                xs: "12px", // smaller font for mobile
                sm: "14px",
                lg: "16px", // larger font for bigger screens
              },
            }}
          >
            <Radio
              value={index.toString()}
              sx={{
                width: {
                  xs: "60px", // smaller radio for mobile
                  lg: "80px", // larger radio for bigger screens
                },
                height: {
                  xs: "60px", // smaller radio for mobile
                  lg: "80px", // larger radio for bigger screens
                },
                "&.Mui-checked": {
                  color: "#1c4546",
                },
              }}
            />

            <Box
              className="p-2 border rounded flex items-center"
              sx={{
                backgroundColor: getBackgroundColor(metricItem?.key),
                display: "flex",
                alignItems: "center",
                borderRadius: "10px",
                justifyContent: "space-between",
                paddingX: "5px",
                width: {
                  xs: "180px", // smaller box for mobile
                  sm: "220px", // larger for medium screens
                },
                height: {
                  xs: "60px", // shorter height for small screens
                  sm: "70px",
                  lg: "80px", // taller box for larger screens
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  gap: "5px",
                  paddingLeft: "3px"
                }}
              >
                <span className="font-normal text-[#00292C]">
                  {metricItem?.metric}
                </span>
                <span className="font-medium text-[#00292C] text-lg">
                  {formatValue(
                    metricItem?.key,
                    performanceData?.selectedDateData?.[metricItem?.key], selectedCurrency, convertAmount
                  ) ?? "N/A"}
                </span>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {performanceData?.selectedDateData?.[metricItem?.key] &&
                  performanceData?.comparisionDateData?.[metricItem?.key] && (
                    <div className="flex flex-col items-center">
                      <img
                        src={
                          calculatePercentageChange(
                            performanceData?.selectedDateData?.[metricItem?.key],
                            performanceData?.comparisionDateData?.[metricItem?.key]
                          ) < 0
                            ? "/DownChart1.png"
                            : "/upArrow.png"
                        }
                        alt="arrow"
                      />
                      <span
                        className={`font-medium ${calculatePercentageChange(
                          performanceData?.selectedDateData?.[metricItem.key],
                          performanceData?.comparisionDateData?.[metricItem?.key]
                        ) < 0
                          ? "text-red-400"
                          : "text-green-400"
                          }`}
                      >
                        {formatValue(
                          metricItem?.key,
                          performanceData?.comparisionDateData?.[metricItem?.key], selectedCurrency, convertAmount
                        )}
                      </span>
                    </div>
                  )}
              </Box>
            </Box>
          </Box>
        ))}
      </RadioGroup>
    </Box>
  );
};

export default PerformanceOverview;