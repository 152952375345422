import React, { useEffect, useState } from "react";
import { dropdownOptions } from "../utils/constants";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import { MultiSelect } from "primereact/multiselect";
import { useConfigureContext } from "../Context/ConfigureContext"
import { Paper, Box, Typography, IconButton, MenuItem, Menu, FormControl, Select, InputLabel } from "@material-ui/core";

const Monitoring = ({ entityTypeData, loadingList }) => {
  const {
    setShowFilterGroup,
    selected,
    setSelected,
    selectedOptions,
    setSelectedOptions,
    preFilledData,
    editConfigation
  } = useConfigureContext();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsActive(!isActive);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsActive(false);
  };

  const handleMenuItemClick = (option) => {
    setSelected(option);
    handleClose();
  };
  // to set selected opotion.....
  useEffect(() => {
    if (preFilledData?.length) {
      const option = dropdownOptions?.find((item) => {
        return item?.key === preFilledData?.[0]?.entityType
      })
      setSelected(option)
    }
    if (editConfigation?.length) {
      const option = dropdownOptions?.find((item) => {
        return item?.key === editConfigation?.[0]?.entityType
      })
      setSelected(option)
    }
  }, [preFilledData, editConfigation])

  const [isActive, setIsActive] = useState(false);

  const handleOptionClick = (option) => {
    setSelectedOptions(option.value);
    setShowFilterGroup(true);
  };
  const isLoading = !entityTypeData || entityTypeData.entities.length === 0;

  useEffect(() => {
    if (editConfigation[0]?.entityIds[0] || preFilledData[0]?.entityIds[0]) {
      if (editConfigation?.length) {
        const entityIdsToFind = editConfigation
          .map((item) => item.entityIds)
          .flat();
        const matchingNames = entityIdsToFind.map((entityId) => {
          const foundObject = entityTypeData?.entities.find(
            (item) => item.id === entityId
          );
          return foundObject ? foundObject : null;
        });
        const filteredMatchingNames = matchingNames.filter(
          (name) => name !== null
        );

        setSelectedOptions(filteredMatchingNames);
      } else {
        const entityIdsToFind = preFilledData
          .map((item) => item.entityIds)
          .flat();
        const matchingNames = entityIdsToFind.map((entityId) => {
          const foundObject = entityTypeData?.entities.find(
            (item) => item.id === entityId
          );
          return foundObject ? foundObject : null;
        });
        const filteredMatchingNames = matchingNames.filter(
          (name) => name !== null
        );
        setSelectedOptions(filteredMatchingNames);
      }
    }
  }, [entityTypeData?.entities]);

  return (
    <div className="summary bg-white shadow mb-5">
      <h3>Run monitoring on</h3>
      <div className="configure-tabbing">
        <div className={" flex align-center justify-space"}>
          <div className="tabbing-open">
            <div className="search-fields">
              <div className="card">
                <MultiSelect
                  key={""}
                  value={selectedOptions}
                  options={entityTypeData?.entities}
                  onChange={handleOptionClick}
                  optionLabel="name"
                  placeholder={loadingList ? "Loading..." : "Search..."}
                  className="w-full md:w-20rem"
                  display="chip"
                  disabled={loadingList} // Disable the MultiSelect when loading
                />
              </div>
            </div>
          </div>

          <Box sx={{ width: '30%', display: 'inline-block' }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="dropdown-label">Level</InputLabel>
              <Select
                label="Level"

                labelId="dropdown-label"
                value={selected}
                renderValue={(selected) => selected?.label || "Monitoring Level"}
              // onChange={handleChange}
              >
                {dropdownOptions.map((option, index) => (
                  <MenuItem
                    key={index}
                    value={option.key}
                    onClick={() => {
                      setSelected(option);
                      setIsActive(!isActive);
                      setSelectedOptions([]);
                    }}
                    className="cursor-pointer hover:text-primaryColor"
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default Monitoring;